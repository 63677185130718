<template>
  <div class="box">
    <div class="info">
      <div class="title">系统修复</div>
      <div class="isnew">
        <div class="version">
          <div class="tips">当前已为最新版本</div>
          <div class="code">v1.2.5</div>
          <div class="time">发布于2023-02-05 14:00:51</div>
        </div>
        <div class="update">
          <ul>
            <li>
              <div class="left add">新增</div>
              <div class="right">【卡片】新增页面通用的30+种展示卡片；</div>
            </li>
            <li>
              <div class="left optimization">优化</div>
              <div class="right">【视觉】优化动画、界面视觉冲击感；</div>
            </li>
            <li>
              <div class="left repair">修复</div>
              <div class="right">【修复】页面跳转问题、板块问题；</div>
            </li>
            <li>
              <div class="left repair">修复</div>
              <div class="right">【修复】已知的问题bug；</div>
            </li>
          </ul>
        </div>
        <div class="upgrade-status">
          <div class="instructions">
            <div class="text">检测更新说明</div>
            <div>文件差异：由于误操作删除了文件或系统更新导致的文件缺失和差异，请及时下载更新</div>
            <div>脚本更新：如果与上次更新数据不统一，请及时更新，如未发生变化则无需更新。</div>
          </div>
          <div class="item">
            <div class="text">上次修复时间：-</div>
          </div>
          <div class="item">
            <div class="icon el-icon-warning"></div>
            <div class="text-warn">
              更新之前自动备份
              <span class="warning">【已开启】</span>
            </div>
          </div>
          <div class="item">
            <div class="text">上次检测时间：-</div>
          </div>
        </div>
        <div class="btn-box" @click="repair">
          <div class="operate-btn">系统修复</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    repair() {
      this.$message({
        showClose: true,
        message: '核验中...',
        type: 'warning'
      })
      setTimeout(() => {
        this.$message({
          message: '已核验，当前无异常，无需修复',
          type: 'success'
        })
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f4f6f8;
  .info {
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      padding: 9px 20px;
      background-color: #f4f6f8;
      border-radius: 2px;
      margin-bottom: 20px;
    }
    .isnew {
      padding: 40px;
      background: #fafdff;
      border: 1px solid #e9edef;
      border-radius: 2px;
      .version {
        border-bottom: 1px solid #e9edef;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .tips {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #19be6b;
          margin-bottom: 20px;
        }
        .code {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #262b30;
          margin-bottom: 10px;
        }
        .time {
          font-size: 12px;
          line-height: 16px;
          color: #939799;
        }
      }
      .update {
        border-bottom: 1px solid #e9edef;
        padding-bottom: 20px;
        ul {
          li {
            font-size: 14px;
            display: flex;
            margin-top: 20px;
            list-style: none;
            .left {
              width: 40px;
              height: 20px;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              flex-shrink: 0;
              &.add {
                color: #197be1;
                background: #e8eeff;
              }
              &.optimization {
                color: #19be6b;
                background: #edfff3;
              }
              &.repair {
                color: #ff9900;
                background: #fff9e6;
              }
              &.repair {
                color: #ff9900;
                background: #fff9e6;
              }
            }
            .right {
              flex: 1;
              font-size: 14px;
              line-height: 20px;
              color: #262b30;
            }
          }
        }
      }
      .upgrade-status {
        padding-top: 20px;
        .item {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: rgb(147, 151, 153);
          line-height: 16px;
          margin-top: 20px;
          .icon {
            font-size: 14px;
            padding-right: 4px;
            color: #f90;
          }
          .text-warn {
            font-size: 14px;
            line-height: 20px;
            color: #262b30;
            span {
              color: #f90;
            }
          }
        }
        .instructions {
          padding: 7px 9px;
          border-radius: 2px;
          margin: 0;
          font-size: 12px;
          line-height: 16px;
          color: #636669;
          border: 1px solid #abdcff;
          background-color: #f0faff;
          > div {
            font-size: 12px;
            line-height: 16px;
            vertical-align: middle;
          }
          .text {
            font-weight: bold;
            margin-bottom: 6px;
          }
        }
      }
      .btn-box {
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 20px;
        .operate-btn {
          font-size: 12px;
          line-height: 17px;
          color: #ffffff;
          cursor: pointer;
          background-color: #2d8cf0;
          padding: 6px 16px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
